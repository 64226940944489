import env from '../env';

import authenticateRequest from './auth/authenticateRequest';
import interceptAuthError from './auth/interceptAuthError';
import confirmAccountNumber from './requests/confirmAccountNumber';
import createNewChangeRequest from './requests/createNewChangeRequest';
import createQuote from './requests/createQuote';
import createSponsorshipProfile from './requests/createSponsorshipProfile';
import createUser from './requests/createUser';
import fetchPresetGuestToken from './requests/fetchPresetGuestToken';
import fetchRepaymentSessionToken from './requests/fetchRepaymentSessionToken';
import fetchSpinwheelIdentityData from './requests/fetchSpinwheelIdentityData';
import fetchSpinwheelSessionToken from './requests/fetchSpinwheelSessionToken';
import fetchTrueworkSessionToken from './requests/fetchTrueworkSessionToken';
import fetchUser from './requests/fetchUser';
import getBorrowerAccountProfiles from './requests/getBorrowerAccountProfiles';
import getCommonApplicationProfile from './requests/getCommonApplicationProfile';
import getCustomerFundingProduct from './requests/getCustomerFundingProduct';
import getCustomerRepaymentProducts from './requests/getCustomerRepaymentProducts';
import getDownstreamReferrals from './requests/getDownstreamReferrals';
import getFundingProductTerms from './requests/getFundingProductTerms';
import getLatestQuoteRequest from './requests/getLatestQuoteRequest';
import getLocations from './requests/getOpportunityLocations';
import getPartnerData from './requests/getPartnerData';
import getPartnerList from './requests/getPartnerList';
import getRepaymentEmployees from './requests/getRepaymentEmployees';
import getRepaymentPartnerList from './requests/getRepaymentPartnerList';
import getSponsorshipAgreement from './requests/getSponsorshipAgreement';
import getSponsorshipAgreementSignLink from './requests/getSponsorshipAgreementSignLink';
import getStudentSponsorshipApplications from './requests/getStudentSponsorshipApplication';
import handleBankEvent from './requests/handleBankEvent';
import getPartnerPortalChangeRequests from './requests/partners/getPartnerPortalChangeRequests';
import getPartnerSponsorshipApplication from './requests/partners/getPartnerSponsorshipApplication';
import getSponsorshipApplications from './requests/partners/getSponsorshipApplications';
import sendPasswordResetEmail from './requests/sendPasswordResetEmail';
import signIn from './requests/signIn';
import signOut from './requests/signOut';
import { staffBackend } from './requests/staff';
import getLocationPreferences from './requests/staff/getLocationPreferences';
import storeRepaymentExternalId from './requests/storeRepaymentExternalId';
import submitAnswersAndLocationPreferences from './requests/submitAnswersAndLocationPreferences';
import submitBorrowerAccountProfileConnection from './requests/submitBorrowerAccountProfileConnection';
import submitCommonApplicationProfile from './requests/submitCommonApplicationProfile';
import submitFiles from './requests/submitFiles';
import submitPaymentRequest from './requests/submitPaymentRequest';
import submitPaymentToPlatformRequest from './requests/submitPaymentToPlatformRequest';
import submitRepaymentFundVerification from './requests/submitRepaymentFundVerification';
import submitUnsupportedServicerDetails from './requests/submitUnsupportedServicerDetails';
import updateAccountNumber from './requests/updateAccountNumber';
import updateCommonApplicationProfile from './requests/updateCommonApplicationProfile';
import updateCommonApplicationProfileFinancingInfo from './requests/updateCommonApplicationProfileFinancingInfo';
import updateUserFPT from './requests/updateFPT';
import updateMailingAddressInfo from './requests/updateMailingAddressInfo';
import updatePassword from './requests/updatePassword';
import updateRepaymentProductConnectionToCompleted from './requests/updateRepaymentProductConnectionToCompleted';
import updateSponsorshipApplicationStatus from './requests/updateSponsorshipApplicationStatusRequest';
import updateStatusStudentSponsorshipApplication from './requests/updateStatusStudentSponsorshipApplication';
import updateTermsAcceptedDateTime from './requests/updateTermsAcceptedDateTime';
import updateUser from './requests/updateUser';
import validateSignup from './requests/validateSignup';
import verifyLiveCalculator from './requests/verifyLiveCalculator';
import verifyResetToken from './requests/verifyResetToken';

import axios, { AxiosRequestConfig } from 'axios';

const backendAxiosRequestConfig: AxiosRequestConfig = {
  baseURL: env.var.LOCAL_TESTING_MODE
    ? env.var.API_URL_INTERNAL
    : env.var.API_URL_EXTERNAL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
  timeout: 20000, // Timeout for Axios
  timeoutErrorMessage: 'Request timed out. Please try again later',
};

/**
 * Axios instance for making anonymous (without JWT),
 *  external (over the internet, non-local network)
 *  requests to the backend server
 */
const backendAnonymousAxios = axios.create(backendAxiosRequestConfig);

/**
 * Axios instance for making authenticated,
 *  external (over the internet, non-local network)
 *  requests to the backend
 *
 * Will automatically retry/repair requests that fail
 *  due to missing/invalid tokens
 */
const backendAuthenticatedAxios = axios.create(backendAxiosRequestConfig);
backendAuthenticatedAxios.interceptors.request.use(authenticateRequest);

backendAuthenticatedAxios.interceptors.response.use(
  (res) => res,
  interceptAuthError,
);

/** A collection of reusable methods for interfacing with the backend */
const backend = {
  ...staffBackend,
  validateSignup,
  confirmAccountNumber,
  createUser,
  createQuote,
  createSponsorshipProfile,
  createNewChangeRequest,
  fetchPresetGuestToken,
  fetchTrueworkSessionToken,
  fetchUser,
  getSponsorshipAgreementSignLink,
  getBorrowerAccountProfiles,
  getCustomerFundingProduct,
  getCustomerRepaymentProducts,
  getDownstreamReferrals,
  getLatestQuoteRequest,
  getLocationPreferences,
  getPartnerPortalChangeRequests,
  getPartnerSponsorshipApplication,
  getSponsorshipApplications,
  handleBankEvent,
  sendPasswordResetEmail,
  signIn,
  signOut,
  submitAnswersAndLocationPreferences,
  submitCommonApplicationProfile,
  submitFiles,
  submitUnsupportedServicerDetails,
  updatePassword,
  updateUser,
  updateUserFPT,
  verifyResetToken,
  verifyLiveCalculator,
  getPartnerList,
  getPartnerData,
  getFundingProductTerms,
  getSponsorshipAgreement,
  updateSponsorshipApplicationStatus,
  getCommonApplicationProfile,
  updateCommonApplicationProfile,
  updateMailingAddressInfo,
  updateCommonApplicationProfileFinancingInfo,
  getLocations,
  getStudentSponsorshipApplications,
  submitBorrowerAccountProfileConnection,
  updateStatusStudentSponsorshipApplication,
  // Repayment endpoints
  fetchSpinwheelIdentityData,
  fetchSpinwheelSessionToken,
  fetchRepaymentSessionToken,
  storeRepaymentExternalId,
  updateAccountNumber,
  submitPaymentRequest,
  submitRepaymentFundVerification,
  submitPaymentToPlatformRequest,
  getRepaymentEmployees,
  getRepaymentPartnerList,
  updateTermsAcceptedDateTime,
  updateRepaymentProductConnectionToCompleted,
};

export default backend;
export {
  backendAnonymousAxios,
  backendAuthenticatedAxios,
};
