import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import useAlert from '../../../hooks/useAlert';
import backend from '../../../lib/backend';
import env from '../../../lib/env';
import applicationBackdropState from '../../../state/atoms/applicationBackdropState';
import repaymentModalOpenState from '../../../state/atoms/repaymentModalOpen';

import { useMethod } from 'react-method-elements';

const MethodFiElementModal: FunctionComponent = () => {
  const [ isRepaymentModalOpen, setIsRepaymentModalOpen ] = useRecoilState(repaymentModalOpenState);
  const setIsApplicationBackdropOpen = useSetRecoilState(applicationBackdropState);
  const { showErrorAlert, showSuccessAlert } = useAlert();

  const methodAPI = useMethod({
    env: env.var.METHODFI_ELEMENTS_API_ENV,
    onEvent: (payload) => {
      console.debug('MethodFi element EVENT:', payload);
    },
    onSuccess: async (payload) => {
      console.debug('MethodFi element SUCCESS:', payload);

      try {
        // entity_id does not exist in IElementClientOnEventPayload MethodFi defined type, but it is received in the payload
        // @ts-ignore
        await backend.storeRepaymentExternalId({ external_id: payload.entity_id });
        showSuccessAlert('Successfully connected to repayment platform.');
        setIsRepaymentModalOpen(false);
      } catch (error) {
        showErrorAlert('Failed to connect to repayment platform. Please try again later.');
      }
    },
    onError: (payload) => {
      console.debug('MethodFi element ERROR:', payload);
    },
    onExit: (payload) => {
      console.debug('MethodFi element EXIT:', payload);
      setIsRepaymentModalOpen(false);
    },
    onOpen: (payload) => {
      console.debug('MethodFi element OPEN:', payload);
      setIsApplicationBackdropOpen(false);
    },
  });

  const initializeModal = useCallback(async () => {
    if (!methodAPI) return;
    const token = await backend.fetchRepaymentSessionToken();
    methodAPI.open(token);
  }, [methodAPI]);

  useEffect(() => {
    if (isRepaymentModalOpen) {
      setIsApplicationBackdropOpen(true);
      initializeModal();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isRepaymentModalOpen, setIsApplicationBackdropOpen ]);

  // Ensure method is loaded.
  if (!methodAPI) return null;

  return <></>;
};

export default MethodFiElementModal;
